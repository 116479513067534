import { Component } from 'react';
import { connect } from 'react-redux';
import { NavigateFunction } from 'react-router-dom';
import { Button } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { getLoglawByCid } from '../api/loglaw';
import { NOTIFICATION_TYPE, notify, withRouter } from '../services';
import { Loglaw } from '../model/loglaw';
import Question from '../components/Question';
import FlowChart from '../components/FlowChart';
import { flowToJson } from '../services';

type Props = {
	token: string,
  user: string,
  groups: {id: number, name: string, description: string}[],
	logined: boolean,
  trial: boolean,
  expired: string,
	id: number | null,
	pid: number | undefined,
	navigate: NavigateFunction
}
type States = {
	pid: number | undefined,
	loglaw: Loglaw | null,
	currentSelect: string
}

class NavigatorStep3 extends Component<Props, States> {

	constructor(props: Props) {
    super(props);
		this.state = {loglaw: null, pid: this.props.pid, currentSelect: ''};
	}

	componentDidMount() {
		if (this.props.logined && this.props.id !== null) {
			getLoglawByCid(this.props.token, this.props.id).then((res: any) => {
				let loglaw: Loglaw = res.data;
				const data = JSON.parse(res.data.data);
				if(data.type === 'canvas') {
					loglaw.data = flowToJson(data.nodes, data.edges);
				}
				const queryParams = new URLSearchParams(window.location.search);
				const id = queryParams.get("id");
				this.setState({loglaw: loglaw, pid: this.props.pid?this.props.pid:(id?Number(id):undefined)});
			}).catch((error: any) => {
				if(error?.data.code === 401 || error?.status === 401) {
					this.props.navigate('/login');
				}
				notify(NOTIFICATION_TYPE.DANGER, '', error?.data.msg ? error?.data.msg : 'Fetch data failed!');
			});
		}
	}
	
	componentDidUpdate(PrevProps: Props, PrevState: States) {
		if (this.props.logined && PrevProps.id !== this.props.id && this.props.id !== null) {
			getLoglawByCid(this.props.token, this.props.id).then((res: any) => {
				let loglaw: Loglaw = res.data;
				const data = JSON.parse(res.data.data);
				if(data.type === 'canvas') {
					loglaw.data = flowToJson(data.nodes, data.edges);
				}
				const queryParams = new URLSearchParams(window.location.search);
				const id = queryParams.get("id");
				this.setState({loglaw: loglaw, pid: this.props.pid?this.props.pid:(id?Number(id):undefined)});
			}).catch((error: any) => {
				notify(NOTIFICATION_TYPE.DANGER, '', error?.data.msg ? error?.data.msg : 'Fetch data failed!');
			});
		}
	}

	setCurrent = (name: string) => {
		this.setState({currentSelect: name});
	}

	render() {
		const name = this.state.loglaw?.name;
		const loglaw = this.state.loglaw?.data;
		const pid = this.state.pid;
		const queryParams = new URLSearchParams(window.location.search);
		let keywords: string | null = queryParams.get("keywords");
		let isFlowchart = (JSON.parse(loglaw?loglaw:"{}"))?.type === 'flowchart';
		return (
			<>
				<hr className="solid margin-top-20" />
				<div className="step-title padding-top-10 padding-bottom-10">
					Step 3. Begin your review of
				</div>
				<div className="margin-top-1 margin-bottom-10 theme-darktealtint-bg topic-bar fg-black">
					<div>{ name }</div>
				</div>
				<Card className="half-white">
					<CardContent>
						<div className="">
							{isFlowchart?<FlowChart json={loglaw} />:<Question user={this.props.user} title={name} cid={this.props.id!==null?this.props.id:-1} token={this.props.token} json={loglaw} setCurrent={this.setCurrent} />}
						</div>
					</CardContent>
				</Card>
				<hr className="solid margin-top-20" />
				<Button className="goback" variant="contained" onClick={()=>this.props.navigate(pid!==undefined?'/step2?id='+pid:(keywords!==null?'/search?keywords='+keywords:'/step1'))}>Go back{keywords!==null?'':' to Step 2'}</Button>
			</>
		);
	}
}

const mapStateToProps = (state: any) => ({
	user: state.user.user,
  token: state.user.token,
  groups: state.user.groups,
  trial: state.user.trial,
	logined: state.user.logined,
  expired: state.user.expired
});

const mapDispatchToProps = (dispatch: any) => {
  return {};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NavigatorStep3));