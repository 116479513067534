import { Component } from 'react';
import { connect } from 'react-redux';
import { NavigateFunction } from 'react-router-dom';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Button } from '@mui/material';
import TreeView from '../components/TreeView';
import { getItems, searchItems } from '../api/loglaw';
import { Role } from '../model/user';
import { Item, Items, ItemArray } from '../model/loglaw';
import { arrayToTree } from "performant-array-to-tree";
import { NOTIFICATION_TYPE, notify, withRouter, sortBy } from '../services';

type Props = {
	token: string,
  user: string,
  groups: {id: number, name: string, description: string}[],
	logined: boolean,
  trial: boolean,
  expired: string,
	id: number | null,
	cid: number | null,
	data: ItemArray[],
	navigate: NavigateFunction
}
type States = {
	data: ItemArray[]
}
class NavigatorStep2 extends Component<Props, States> {

  constructor(props: Props) {
    super(props);
		this.state = {data: []};
	}

	componentDidMount() {
		let path = window.location.pathname;
		if (this.props.logined && this.props.data.length === 0) {
			const roles = this.props.groups.filter((role: Role)=>role.name!=='superadmin'&&role.name!=='admin');
			const selectedrole = roles.map((role: Role)=>role.id)[0];
			const queryParams = new URLSearchParams(window.location.search);
			let keywords: string | null = queryParams.get("keywords");
			if(path === '/step2') {
				getItems(this.props.token, Number(selectedrole)).then((res: any) => {
					let data = res.data;
					data.push({id: 0, parentid: null});
					data = arrayToTree(data, { id: "id", parentId: "parentid", childrenField: "child",dataField: null });
					data = sortBy(data[0].child, true, 'ordernum');
					this.setState({data: data});
				}).catch((error: any) => {
					if(error?.data.code === 401 || error?.status === 401) {
						this.props.navigate('/login');
					}
					notify(NOTIFICATION_TYPE.DANGER, '', error?.data.msg ? error?.data.msg : 'Fetch data failed!');
				});
			} else if(path === '/search' && keywords !== null && keywords.trim() !== '') {
				if(keywords !== null) {
					searchItems(this.props.token, Number(selectedrole), keywords).then((res: any) => {
						let data = res.data;
						this.setState({data: data});
					}).catch((error: any) => {
						if(error?.data.code === 401 || error?.status === 401) {
							this.props.navigate('/login');
						}
						notify(NOTIFICATION_TYPE.DANGER, '', error?.data.msg ? error?.data.msg : 'Fetch data failed!');
					});
				} else {
					this.props.navigate('/');
				}
			}
		} else {
			this.setState({data: this.props.data});
		}
	}

	componentDidUpdate(prevProps: Readonly<Props>): void {
		if(this.props.data !== prevProps.data) {
			this.setState({data: this.props.data});
		}
	}

	selected = (id: number) => {
		let path = window.location.pathname;
		if(path === '/step2') {
			this.props.navigate(this.props.id!==undefined?'/step3?id='+this.props.id+'&cid='+id:'/step3?cid='+id);
		} else if (path === '/search') {
			const queryParams = new URLSearchParams(window.location.search);
			let keywords: string | null = queryParams.get("keywords");
			this.props.navigate(keywords!==null?'/step3?keywords='+keywords+'&cid='+id:'/step3?cid='+id);
		}
	}

	render() {
		let path = window.location.pathname;
		let data: Items[] | ItemArray | undefined = this.state.data.find((item: Item) => item.id === this.props.id);
		let topic: string | undefined = data?.name;
		const queryParams = new URLSearchParams(window.location.search);
		let keywords: string | null = queryParams.get("keywords");
		if (data !== undefined && data !== null) {
			data = data?.child?.sort((a:Items,b:Items) => a.ordernum - b.ordernum);
		}
		if (path === '/search') {
			data = this.state.data.sort((a:Items,b:Items) => a.ordernum - b.ordernum);
		}
		return (
			<>
				<div className="topic-bar">
					{path==='/step2'?<div>Topic: { topic }</div>:<div>Search result for: { keywords }</div>}
				</div>
				<hr className="solid margin-top-20" />
				{path==='/step2'?<div className="step-title">Step 2. Narrow your enquiry</div>:(this.state.data.length===0?<div className="step-title">No result for search keywords</div>:<></>)}
				<Card className="half-white">
					<CardContent>
						<div className="list-loglaw">
							<TreeView select={this.selected} data={data} selected={this.props.cid!==null?this.props.cid:-1} />
						</div>
					</CardContent>
				</Card>
				<hr className="solid margin-top-20" />
				<Button className="goback" variant="contained" onClick={()=>this.props.navigate('/')}>Go back to Step 1</Button>
				
			</>
		);
	}
}

const mapStateToProps = (state: any) => ({
	user: state.user.user,
  token: state.user.token,
  groups: state.user.groups,
  trial: state.user.trial,
	logined: state.user.logined,
  expired: state.user.expired
});

const mapDispatchToProps = (dispatch: any) => {
  return {};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NavigatorStep2));