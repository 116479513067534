import axios from 'axios';
import { config } from '../config';
import { Activity } from '../model/activity';
import { Answer } from '../model/answer';

export const logActivity = (token: string, log: Activity[]) => {
  return new Promise((resolve, reject) => {
    axios
      .post(config.url.API_URL+'log', log.filter(item => item !== null), {headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization':'Bearer '+token }})
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          if(err?.response?.status === 401 || err?.status === 401) {
            window.location.href = '/login';
          }
          reject(err.response ? err.response : err);
        });
  });
}

export const logAnswer = (token: string, log: Answer) => {
  return new Promise((resolve, reject) => {
    axios
      .post(config.url.API_URL+'log/answer', log, {headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization':'Bearer '+token }})
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          if(err?.response?.status === 401 || err?.status === 401) {
            window.location.href = '/login';
          }
          reject(err.response ? err.response : err);
        });
  });
}