import axios from 'axios';
import { config } from '../config';

export const user = (token: string) => {
  return new Promise((resolve, reject) => {
    axios
      .get(config.url.API_URL+'user', {headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization':'Bearer '+token }})
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          if(err?.response?.status === 401 || err?.status === 401) {
            window.location.href = '/login';
          }
          reject(err.response ? err.response : err);
        });
  });
}

export const login = (username: string, password: string) => {
  return new Promise((resolve, reject) => {
    axios
      .post(config.url.API_URL+'user/auth', 
        { 
          username:username, 
          password:password 
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err.response ? err.response : err);
        });
  });
}

export const logout = (token: string) => {
  return new Promise((resolve, reject) => {
    axios
      .post(config.url.API_URL+'user/logout', {token: token})
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err.response ? err.response : err);
        });
  });
}

export const refreshToken = (token: string) => {
  return new Promise((resolve, reject) => {
    axios
      .post(config.url.API_URL+'user/refresh', {token: token})
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          if(err?.response?.status === 401 || err?.status === 401) {
            window.location.href = '/login';
          }
          reject(err.response ? err.response : err);
        });
  });
}