import axios from 'axios';
import { config } from '../config';

export const form = (token: string, to: string, type: string, message: string, name?: string, email?: string) => {
  return new Promise((resolve, reject) => {
    axios
      .post(config.url.API_URL+'message/form', 
        { 
          to:to,
          type:type, 
          message:message,
					name:name,
					email:email
        },
        {headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization':'Bearer '+token }})
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          if(err?.response?.status === 401 || err?.status === 401) {
            window.location.href = '/login';
          }
          reject(err.response ? err.response : err);
        });
  });
}
