import axios from 'axios';
import { config } from '../config';
export const getLoglawByCid = (token: string, id: number) => {
  return new Promise((resolve, reject) => {
    axios
      .get(config.url.API_URL+'loglaw/cid/'+id, {headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization':'Bearer '+token }})
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          if(err?.response?.status === 401 || err?.status === 401) {
            window.location.href = '/login';
          }
          reject(err);
        });
  });
}

export const getItems = (token: string, role: number) => {
  return new Promise((resolve, reject) => {
    axios
      .get(config.url.API_URL+'loglaw/items/'+role, {headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization':'Bearer '+token }})
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          if(err?.response?.status === 401 || err?.status === 401) {
            window.location.href = '/login';
          }
          reject(err.response ? err.response : err);
        });
  });
}

export const searchItems = (token: string, role: number, keywords: string) => {
  return new Promise((resolve, reject) => {
    axios
      .get(config.url.API_URL+'loglaw/items/'+role+'/'+keywords, {headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization':'Bearer '+token }})
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          if(err?.response?.status === 401 || err?.status === 401) {
            window.location.href = '/login';
          }
          reject(err.response ? err.response : err);
        });
  });
}